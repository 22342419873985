import { Container } from "react-bootstrap";
import TitleUI from "@/ui/Title";

export default function Title({ title, className = "pt-4 pt-md-5 text-center" }) {
  return (
    <>
      {title &&
        <Container className={className}>
        <TitleUI title={title} />
        </Container>
      }
    </>
  );
}
